
/* monserat */
@font-face {
  font-family: 'monseratregular';
  src: url('../assets/fonts/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'monseratsemibold';
  src: url('../assets/fonts/fonts/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'monseratmedium';
  src: url('../assets/fonts/fonts/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'monseratbold';
  src: url('../assets/fonts/fonts/OpenSans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Oswald Regular';
  src: url('../assets/fonts/fonts/Oswald/Oswald-Regular.ttf');
}

@font-face {
  font-family: 'Oswald Medium';
  src: url('../assets/fonts/fonts/Oswald/Oswald-Medium.ttf');
}

@font-face {
  font-family: 'Oswald Bold';
  src: url('../assets/fonts/fonts/Oswald/Oswald-Bold.ttf');
}