@import 'styles/tables.css';
@import 'styles/react-table.css';
@import 'styles/font.css';


// * {
//   font-family: "selawk", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif !important;
// }

* {
  font-family: "monseratregular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  // user-select: none; // for disabling user select
}

// general SASS

img {
  -webkit-user-drag: none;
}

.MuiCircularProgress-colorSecondary {
  color: #E45C96 !important;
}

// selawik
.selawkregular {
  font-family: "selawkregular" !important;
}

.selawkbold {
  font-family: "selawkbold" !important;
}

.selawksemibold {
  font-family: "selawksemibold" !important;
}

.selawklight {
  font-family: "selawklight" !important;
}

.selawksemilight {
  font-family: "selawksemilight" !important;
}

// monserat

.monseratregular {
  font-family: "monseratregular" !important;
}

.monseratsemibold {
  font-family: "monseratsemibold" !important;
}

.monseratmedium {
  font-family: "monseratmedium" !important;
}

.monseratbold {
  font-family: "monseratbold" !important;
}



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.fullScreenLoader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 128px;
    margin-bottom: 32px;
    visibility: visible;
    opacity: 1;
    transform-origin: 50% 50% 0px;
    transform: scaleX(1) scaleY(1);
  }

}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  border: none;
  outline: 0;
  border-radius: 0px;
  background: #F9F9F9;
}

.stripe-label-error {
  color: #D13838 !important;
}

.stripe-input-error{
  border: 1px solid #e62946 !important

}

.stripe-error-container{
  background: rgb(251, 234, 234);
  color: rgb(209, 56, 56);
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 11px; */
  margin-top: -17px;
  /* width: 573px; */
  margin-left: -23px;
  margin-right: -23px;
}

input::placeholder {
  color: #AAB7C4;
}

input:focus,
.StripeElement--focus {
  border: 1px solid #bababa;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #F5F5F5,
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
  border-radius: 20px;
}

.swiper-button-next,
.swiper-button-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 40px;
  width: 40px;
  outline: none;
}

.swiper-button-next {
  right: -44px !important;

}


.swiper-button-prev {
  left: -44px !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "\276F";
  width: 1em;
  text-align: center;
  transition: all .35s;
  font-size: 20px;
  opacity: 0.6;
}

.swiper-button-prev::after {
  content: "next" !important;
  font-size: 40px !important;
  transform: rotate(180deg) !important;
}

.swiper-button-next::after {
  font-size: 40px !important;
}

.swiper-pagination{
  bottom: -15px !important;
}

.hidden {
  visibility: hidden;
}

.swiper-pagination-bullet-active{
  border: none !important;
  outline: none !important;
}

.fontBold{
  font-family: 'monseratsemibold'  !important;
}

.pr-10{
  padding-right: 10px;
}


@media screen and (max-width: 768px) {
  .swiper-slide {
    padding: 20px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  } 
}